import { likeRegExp } from '@/offline/database'

export default {
  _selectDatoSistemaBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.dato_sistema)
      .innerJoin(
        tables.tdato_sistema,
        tables.dato_sistema.idtdato_sistema.eq(tables.tdato_sistema.idtdato_sistema)
      )
      .orderBy(tables.dato_sistema.orden)
  },
  async selectDatoSistema (Vue, filter, search, sorter, page, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.dato_sistema.valor.match(reQ),
          tables.tdato_sistema.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.dato_sistema.idsistema.eq(idsistema),
        tables.dato_sistema.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectDatoSistemaBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectDatoSistemaRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectDatoSistemaBase(Vue)
      .where(tables.dato_sistema.iddato_sistema.in(pks))
      .exec()
  }
}
